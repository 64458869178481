<template>
  <a-modal
    title="预览图片"
    :footer="null"
    :width="900"
    :visible="visible"
    @cancel="handleCancel"
  >
    <a-spin :spinning="divLoading">
      <div style="text-align: center">
        <img :src="src" style="max-width: 99%">
      </div>
    </a-spin>
  </a-modal>
</template>
<script>
  export default {
    data () {
      return {
        visible: false,
        src: '',
        divLoading: false
      }
    },
    methods: {
      /**
       * 初始化
       */
      preview (record) {
        this.visible = true
        this.divLoading = true
      },
     
      /**
       * 转图片类型
       */
      downloadfile (res) {
        const blob = new Blob([res])
        this.src = window.URL.createObjectURL(blob)
      },
      handleCancel () {
        this.src = ''
        this.visible = false
      }
    }
  }
</script>
